import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageSection = _resolveComponent("ImageSection")!
  const _component_TextImage = _resolveComponent("TextImage")!
  const _component_Accommodation = _resolveComponent("Accommodation")!
  const _component_Tiles = _resolveComponent("Tiles")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ImageSection, _normalizeProps(_guardReactiveProps(_ctx.cover)), null, 16),
    _createVNode(_component_TextImage, _normalizeProps(_guardReactiveProps(_ctx.gallery)), null, 16),
    _createVNode(_component_Accommodation, _normalizeProps(_guardReactiveProps(_ctx.accommodation)), null, 16),
    _createVNode(_component_Tiles, _normalizeProps(_guardReactiveProps(_ctx.facilities)), null, 16)
  ]))
}