
import { defineComponent } from "vue";
import Header from "@/components/non-contextual/header/Header.vue";
import ImageSection from "@/components/non-contextual/image-section/ImageSection.vue";
import { shellHeaderConfig } from "./shell.config";
import CompFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "ViewShell",
  components: {CompFooter, Header, ImageSection},
  data() {
    return {
      // state: this.$state,
      header: shellHeaderConfig,
      book: {
        imagePath: "images/home/book.jpg",
        title: "",
        subTitle: {
          en: "",
          fr: ""
        },
        action: {
          label: {
            en: 'Book Now',
            fr: 'Reserve maintenant'
          },
          press: () => {
            if(this.$i18n.locale === 'fr'){
             return window.open("https://guillerin-caravan-and-glamping.amenitiz.io/fr/booking/room", '_blank')
            } else {
              return window.open("https://guillerin-caravan-and-glamping.amenitiz.io/en/booking/room", '_blank')
            }
          }
        },
      }
    }
  },
  async mounted() {
    // await this.state().loadPage()
  }
});
