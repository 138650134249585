
import {defineComponent, PropType} from "vue";
import {Tile} from "./Tile.vue";
import {printStr} from "../core/helper";

export default defineComponent({
  name: "CompTiles",
  props: {
    subTitle: [String, Function] as PropType<(() => string) | string>,
    title: [String, Function] as PropType<(() => string) | string>,
    content: [Array, Function] as PropType<(() => string)[] | string[]>,
    cards: [Array] as PropType<Tile[]>
  },
  methods: {
    print(item: string | (() => string)): string {
      return printStr(item)
    }
  },
});
