import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ImageSection = _resolveComponent("ImageSection")!
  const _component_CompFooter = _resolveComponent("CompFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header, _normalizeProps(_guardReactiveProps(_ctx.header)), null, 16),
      _createVNode(_component_router_view),
      _createVNode(_component_ImageSection, _normalizeProps(_guardReactiveProps(_ctx.book)), null, 16)
    ]),
    _createVNode(_component_CompFooter)
  ], 64))
}