
import { defineComponent } from "vue";
import ViewShell from "@/views/Shell.vue";

export default defineComponent({
  name: "App",
  components: {
    ViewShell,
  },
});
