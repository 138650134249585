
import {defineComponent, PropType} from "vue";
import {printStr} from "../../../core/helper";
import {Translations} from "../../../core/interfaces";
// import EditableText from "@/components/non-contextual/editable-text/EditableText.vue";

export default defineComponent({
  name: "CompImageSection",
  emits: ['buttonClicked'],
  props: {
    imagePath: {
      type: [String, Function] as PropType<(() => string) | string>,
      required: true
    },
    title: [String, Function, Object] as PropType<(() => string) | string | Translations>,
    subTitle: [String, Function, Object] as PropType<(() => string) | string | Translations>,
    isFullPage: Boolean,
    action: Object as PropType<{
      label: (() => string) | string | Translations;
      press: () => void;
    }>,
  },
  methods: {
    getStyle() {
      return `background: url(${this.print(this.imagePath)}) no-repeat center center fixed;
              background-repeat: no-repeat;
              background-size: cover;
      `;
    },
    pressed() {
      if (this.action && this.action.press) {
        this.action.press()
      }
      this.$emit("buttonClicked", true);
    },
    print(item: string | (() => string) | Translations | undefined): string {
      return (typeof item === 'object') ?
          (item as Translations)[this.$i18n.locale as keyof Translations] : printStr(item ? item : '')
    }
  }
});
