import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-items-center site-hero-inner justify-content-center" }
const _hoisted_3 = { class: "col-md-12 text-center" }
const _hoisted_4 = { class: "mb-5 animate__animated animate__fadeInUp" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(`site-hero overlay ${this.isFullPage ? 'site-hero-full-page' : 'site-hero-section'}`),
    "data-stellar-background-ratio": "0.5",
    style: _normalizeStyle(_ctx.getStyle())
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.print(_ctx.title)), 1),
            (_ctx.subTitle)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.print(_ctx.subTitle)), 1))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.action)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pressed && _ctx.pressed(...args)))
                }, _toDisplayString(_ctx.print(_ctx.action.label)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 6))
}