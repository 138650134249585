
import {defineComponent, PropType} from "vue";
import {printStr} from "../../../core/helper";
import {Translations, TranslationsArray} from "../../../core/interfaces";

export default defineComponent({
  name: "CompFullImageText",
  props: {
    imagePath: String,
    title: [String, Function, Object] as PropType<(() => string) | string | Translations>,
    subTitle: [String, Function, Object] as PropType<(() => string) | string | Translations>,
    content: [Function, Array, Object] as PropType<(() => string[]) | string[] | TranslationsArray>,
    action: Object as PropType<{
      label: (() => string) | string
      press: () => void
    }>,
  },
  data() {
    return {
      state: this.$state
    }
  },
  methods: {
    print(item: string | (() => string) | Translations | undefined): string {
      return (typeof item === 'object') ?
          (item as Translations)[this.$i18n.locale as keyof Translations] : printStr(item ? item : '')
    },
    getContent(): string[] {
      if (this.content && (this.content as TranslationsArray).en) {
        return (this.content as TranslationsArray)[this.$i18n.locale as keyof Translations]
      }
      return ((this.content) ? ((typeof this.content == 'function') ? this.content() : this.content) : []) as string[]
    },
    pressed() {
      if (this.action && this.action.press)
        this.action.press()
    }
  },
});
