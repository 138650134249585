
import { defineComponent } from 'vue'
import { Translations } from '../core/interfaces'
import { printStr } from '../core/helper'


export default defineComponent({
  name: "CompFooter",
  data() {
    return {
      text: {
        location: () => this.$t('footer.location'),
        contact: () => this.$t('footer.contact'),
      }
    }
  },
  methods: {
    print(item: string | (() => string) | Translations | undefined): string {
      return (typeof item === 'object') ?
          (item as Translations)[this.$i18n.locale as keyof Translations] : printStr(item ? item : '')
    }
  },
});
