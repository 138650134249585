
import {defineComponent, PropType} from "vue";
import {DynamicString, Translations} from "../../../core/interfaces";
import {printStr} from "../../../core/helper";
import {HeaderClasses, HeaderNavItem, HeaderProps} from "./header.interfaces";

export default defineComponent({
  name: "CompHeader",
  props: {
    classes: {
      type: Object as PropType<HeaderClasses>,
      required: false
    },
    logo: {
      type: Object as PropType<HeaderProps>,
      required: false
    },
    navItems: {
      type: Array as PropType<HeaderNavItem[]>,
      required: true
    },
    isWide: {
      type: Boolean,
      required: false
    },
    isFixed: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      show: Boolean
    }
  },
  methods: {
    getActive(route: string): string {
      return this.$route.path == route ? 'active' : ''
    },
    lang(lang: string): void {
      this.$i18n.locale = lang
    },
    print(item: DynamicString | undefined): string {
      return (typeof item === 'object') ?
          (item as Translations)[this.$i18n.locale as keyof Translations] : printStr(item ? item : '')
    }
  },
});
