
import {defineComponent, PropType} from "vue";
import {printStr} from "../../core/helper";
import {DynamicString, Translations} from "../../core/interfaces";

interface Options {
  title: (() => string) | string;
  subTitle?: (() => string) | string;
  actions: {
    label: (() => string) | string;
  };
  details?: (() => string)[] | string[];
  content?: (() => string)[] | string[];
}

export default defineComponent({
  name: "CompAccommodation",
  props: {
    imagePath: String,
    title: [String, Function] as PropType<(() => string) | string>,
    subTitle: [String, Function] as PropType<(() => string) | string>,
    main: Object as PropType<Options>,
    second: Object as PropType<Options>,
    third: Object as PropType<Options>,
    fourth: Object as PropType<Options>,
    action: Object as PropType<{
      label: string;
    }>,
  },
  data() {
    return {
      viewButton: {
        en: 'View All',
        fr: 'Voir tout'
      }
    }
  },
  methods: {
    getStyle() {
      return `background: url(${this.imagePath}) no-repeat center center fixed;
              background-repeat: no-repeat;
              background-size: cover;
      `;
    },
    print(item: DynamicString | undefined): string {
      return (typeof item === 'object') ?
          (item as Translations)[this.$i18n.locale as keyof Translations] : printStr(item ? item : '')
    },
    viewAll() {
      this.$router.push('accommodation')
    }
  },
});
